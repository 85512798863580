window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

// feather-icons
window.feather = require('feather-icons');
// jscroll
window.jscroll = require('jscroll');
// clipboard js
window.ClipboardJS = require('clipboard');
// color picker
window.spectrum = require('spectrum-colorpicker2');
// toastify-js
window.Toastify = require('toastify-js');

require('@creativebulma/bulma-tagsinput/dist/js/bulma-tagsinput.min.js');
