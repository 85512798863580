require('./bootstrap');

let collection = JSON.parse(localStorage.getItem("collection")) ?? [];

$(function() {

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": jQuery('meta[name="csrf-token"]').attr("content"),
        },
    });

    $('ul.pagination').hide();
    
    $('.scrolling-pagination').jscroll({
        debug: true,
        autoTrigger: true,
        padding: 0,
        nextSelector: '.pagination__next',
        contentSelector: '.scrolling-page',
        callback: function() {
            $('ul.pagination').remove();
            $('.jscroll-added').children().unwrap();
            collection.forEach(element => {
                $(`.js-save-button-${element}`).addClass('is-active');
            });
            feather.replace();
            
        }
    });

    $("select[name='sort']").change(function () {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('sort', $(this).val());
        window.location.search = decodeURIComponent(searchParams.toString());
    });
    $( '#app' ).on( 'click' , '.js-save-button', function (e) {
        e.preventDefault();
        const palette = $(this).data('palette');
        const index = collection.indexOf(palette);
        const counter = $(this).children(".js-save-span");
        let url = '';
        if (index >= 0) {
            collection.splice(index, 1);
            url = '/api/palettes/unsave/';
        } else {
            collection.push(palette);
            url = '/api/palettes/save/';
        }
        $.ajax({
            type: "POST",
            url: url + palette,
            dataType: "json",
            success: (response) => {
                counter.text(response);
            },
        });
        localStorage.setItem('collection', JSON.stringify(collection));
        $(this).toggleClass('is-active');
    });

    collection.forEach(element => {
        $(`.js-save-button-${element}`).addClass('is-active');
    });

    $(".navbar-burger").click(function() {
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");
    });
});

var clipboard = new ClipboardJS('.js-clipboard', {
    text: function(trigger) {
        text = trigger.getAttribute('data-clipboard-text');
        if(text.charAt(0) === '#')
        {
            text = text.substring(1);
        }
        return text;
    }
});

clipboard.on('success', function(e) {
    const element = e.trigger;
    const text = element.getAttribute('data-clipboard-text');
    // console.info('Action:', e.action);
    // console.info(e.trigger.dataset.clipboard-text);
    // console.info('Trigger:', e.trigger);
    // const element = e.trigger;
    if(text.charAt(0) === 'h'){
        Toastify({
            text: ' لینک کپی شد!',
            gravity: "bottom",
            position: "left",
            duration: 3000,
            escapeMarkup: false
        }).showToast();
        
    } else {
        element.innerHTML = "کپی شد";
        setTimeout(function(){
            element.innerHTML = text;
        }, 1000);
    }
    e.clearSelection();
});




feather.replace();